import "./../css/main.css";
import Slider from "react-slick";
import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { motion } from "framer-motion";
import Form from "../form/form";

export const screenWidth = () => window.screen.width;
const uniqueId = () => performance.now() * Math.random();

const objSecurityContent = [
	{
		id: 1,
		title: "Офисные здания и бизнес-центры ",
		description:
			"Обеспечение безопасности сотрудников и ограничение доступа к офисным помещениям",
	},
	{
		id: 2,
		title: "ОБРАЗОВАТЕЛЬНЫЕ УЧРЕЖДЕНИЯ",
		description:
			"Ограничение доступа к учебным корпусам и обеспечение безопасности учащихся и преподавателей",
	},
	{
		id: 3,
		title: "ПРОМЫШЛЕННЫЕ ПРЕДПРИЯТИЯ",
		description:
			"Контроль доступа на территорию завода или фабрики и обеспечение безопасности производственного процесса",
	},
	{
		id: 4,
		title: "Офисные здания и бизнес-центры",
		description:
			"Обеспечение безопасности сотрудников и ограничение доступа к офисным помещениям",
	},
	{
		id: 5,
		title: "ОБРАЗОВАТЕЛЬНЫЕ УЧРЕЖДЕНИЯ",
		description:
			"Ограничение доступа к учебным корпусам и обеспечение безопасности учащихся и преподавателей",
	},
	{
		id: 6,
		title: "ПРОМЫШЛЕННЫЕ ПРЕДПРИЯТИЯ",
		description:
			"Контроль доступа на территорию завода или фабрики и обеспечение безопасности производственного процесса",
	},
];

export const mainInfoContent = {
	cot: {
		title: "ЦЕНТР ОХРАНЫ ТРУДА",
		description: `
		Мы предлагаем полный комплекс услуг в области охраны труда: от проведения специальной оценки условий труда до расследования несчастных случаев.
		<br>
		<br>
		Наши специалисты обладают высоким уровнем профессионализма, что позволяет определить оптимальную стратегию и найти верное решение для самых сложных задач. 
		Сотрудничество с нами – это гарантия достижения целей и реализации поставленных задач!`,
		logo: "logos/cot_logo.svg",
		cards: [
			{
				title: "120 организаций доверяют нам ведение охраны труда",
				description: `Нам доверяют как крупные предприятия, так и малый бизнес. Мы ведем охрану труда «под ключ» и гарантируем результат работы`,
				backgroundImage: "cards/cot_shield.svg",
			},
			{
				title: "180 проверок ГИТ сопроводили за пять лет",
				description: `Мы обладаем обширным опытом сопровождения различных надзорных мероприятий: от плановых проверок до смертельных несчастных случаев`,
				backgroundImage: "cards/cot_checkmark.svg",
			},
			{
				title: "Консультационная поддержка для наших Заказчиков",
				description: `Для наших Клиентов мы оказываем бесплатные консультации по вопросам охраны труда и трудового законодательства`,
				backgroundImage: "cards/cot_support.svg",
			},
		],
		buttonColor: "#F9BD6E",
	},
	med: {
		title: "МЕДИЦИНСКИЙ ЦЕНТР «UNIONMED»",
		description: `
		Мы оказываем комплекс медицинских услуг для юридических и физических лиц в Санкт-Петербурге и Ленинградской области: 
		медицинские осмотры, оформление личных медицинских книжек, вакцинация и многое другое.
		<br>
		<br>
		Медицинские центры расположены в шаговой доступности от метро:
		<br>
		<br>
		- ул. Курская, д. 21, лит. А (5 мин. от. ст. м. «Обводный канал»)
		<br>
		<br>
		- ул. Гданьская, д. 18, к. 3 (10 мин. от ст. м. «Удельная»)
		`,
		logo: "logos/med_logo.svg",
		cards: [
			{
				title: "2 медицинских центра в Санкт-Петербурге",
				description: `Для удобства наших Клиентов медицинские центры расположены в пешей доступности от станций метро Обводный канал и Удельная`,
				backgroundImage: "cards/med_location.svg",
			},
			{
				title: "Проведение выездных медицинских осмотров",
				description: `Организация медицинских осмотров на территории Заказчика позволяет значительно сократить временные затраты на проведение осмотров `,
				backgroundImage: "cards/med_car.svg",
			},
			{
				title: "> 40 000 медицинских осмотров ежегодно",
				description: `Опытный медицинский персонал, квалифицированные менеджеры и администраторы, отсутствие очередей и быстрые сроки подготовки документов `,
				backgroundImage: "cards/med_exam.svg",
			},
		],
		buttonColor: "#4F7853",
	},
	ucheb: {
		title: "УЧЕБНЫЙ ЦЕНТР",
		description: `
		Современная образовательная организация. 
		Мы оказываем услуги по обучению персонала по программам охраны труда, дополнительного профессионального образования и профессионального обучения.
		<br>
		<br>
		Опытный педагогический состав и обширная материально-техническая база позволяют нам осуществлять образовательную деятельность практически по любым направлениям, 
		а удобная платформа дистанционного обучения позволяет повысить эффективность образовательного процесса!
		`,
		logo: screenWidth() <= 1280 ? "logos/trud_logo_mobile.svg" : "logos/trud_logo.svg",
		cards: [
			{
				title: "Выездные обучения на территории Заказчика",
				description: `Позволят сэкономить десятки человеко-часов, не жертвуя при этом качеством образовательного процесса, что в результате дает максимальный эффект`,
				backgroundImage: "cards/trud_hat.svg",
			},
			{
				title: "> 20 000 слушателей ежегодно",
				description: `Нам доверяют обучение персонала более 500 постоянных Заказчиков: от небольших фирм до крупных оборонных концернов`,
				backgroundImage: "cards/trud_listener.svg",
			},
			{
				title: "> 120 Организаций доверяют нам ведение охраны труда",
				description: `Нам доверяют как крупные предприятия, так и малый бизнес. Мы ведем охрану труда «под ключ» и гарантируем результат работы`,
				backgroundImage: "cards/trud_emergency.svg",
			},
		],
		buttonColor: "#0F7290",
	},
	center: {
		title: "Школа охранников «Центр содействия»",
		description: `
		Школа охранников «Центр содействия» - не просто учебный центр для охранников в Санкт-Петербурге и Ленинградской области. 
		Мы оказываем полный комплекс услуг по подготовке граждан к осуществлению охранной деятельности. 
		<br>
		<br>
		Работа с нами позволяет значительно сократить временные и финансовые затраты на прохождение обучения, 
		медицинского освидетельствования и подготовку необходимого комплекта документов для получения удостоверения частного охранника!`,
		logo: "logos/center_logo.svg",
		cards: [
			{
				title: "Получение удостоверение частного охранника",
				description: `Полное сопровождение в получении удостоверения частного охранника 4, 5 или 6 разряда: от консультации до приема в ОЛРР Росгвардии`,
				backgroundImage: "cards/center_card.svg",
			},
			{
				title: "Медицинское заключение для работы частным охранником",
				description: `Прохождение медицинской комиссии и получение справки по форме 002 ЧО/у за один визит в нашем медцентре`,
				backgroundImage: "cards/center_document.svg",
			},
			{
				title: "> 5 000 охранников ежегодно обучаются у нас",
				description: `Опытный педагогический персонал позволит получить необходимые для работы охранником знания и навыки`,
				backgroundImage: "cards/center_book.svg",
			},
		],
		buttonColor: "#3B80A7",
	},
	aut: {
		title: "АУТСТАФФИНГ ПЕРСОНАЛА",
		description: `
		Среди множества инновационных подходов к управлению кадровым ресурсами, 
		аутстаффинг персонала выделяется своей уникальной способностью менять облик современного бизнеса. 
		Этот метод управления трудовыми ресурсами лишен формальностей и стандартных ограничений, 
		связанных с самостоятельным учетом наемных работников`,
		logo: "logos/staff_logo.svg",
		cards: [
			{
				title: "Миграционный учет «под ключ»",
				description: `Отправляем уведомления и документы в органы УМВД и УФМС, контролируем платежи по патентам, помогаем с оформлением регистраций`,
				backgroundImage: "cards/staff_people.svg",
			},
			{
				title: "Поможем сэкономить до 30% от суммы затрат на ФОТ",
				description: `Затраты на персонал при работе с нами – это статья расхода, облагаемая НДС, что позволяет оптимизировать расходы и увеличить прибыль`,
				backgroundImage: "cards/staff_wallet.svg",
			},
			{
				title: "Берем всю ответственность за работников на себя",
				description: `Организуем все процедуры по охране труда: от вводного инструктажа до расследования несчастных случаев на производстве`,
				backgroundImage: "cards/staff_responsibility.svg",
			},
		],
		buttonColor: "#393A5A",
	},
	massive: {
		title: "ОХРАННАЯ ОРГАНИЗАЦИЯ",
		description: `
		Мы оказываем качественные услуги в области обеспечения физической безопасности: 
		от постовой охраны и сопровождения грузов до монтажа СКУД и установки систем видеонаблюдения
		<br>
		<br>
		Сотрудники нашего охранного предприятия обладают необходимым опытом и знаниями для выполнения задач по обеспечению безопасности на самом высоком уровне.
		Мы делаем все возможное, чтобы наши Клиенты были спокойны и уверены как в собственной безопасности, так и в безопасности своего имущества и бизнеса
		`,
		logo: "logos/massive_logo.svg",
		cards: [
			{
				title: "> 15 лет опыта в сфере оказания охранных услуг",
				description: `Компания начала свою деятельность в 2000-е годы и продолжает стремительно развиваться`,
				backgroundImage: "cards/massive_star.svg",
			},
			{
				title: "> 700 объектов под нашей охраной",
				description: `Опытный и квалифицированный персонал готов приступить к охране ваших объектов со всей ответственностью`,
				backgroundImage: "cards/massive_cube.svg",
			},
			{
				title: "< 15 мин до прибытия группы быстрого реагирования",
				description: `Незамедлительно выезжаем на охраняемые объекты по «тревожной кнопке» в любое время суток`,
				backgroundImage: "cards/massive_clock.svg",
			},
		],
		buttonColor: "#0C713F",
	},
};

const baseCards = [
	{
		title: "ОТЛИЧИЕ СОВМЕСТИТЕЛЬСТВА ОТ СОВМЕЩЕНИЯ",
		image: "base_cards/base_card1.svg",
	},
	{
		title: "ВНЕПЛАНОВАЯ СПЕЦИАЛЬНАЯ ОЦЕНКА УСЛОВИЙ ТРУДА",
		image: "base_cards/base_card2.svg",
	},
	{
		title:
			"КАК ПРОВЕСТИ ИНСТРУКТАЖ ЭЛЕКТРОБЕЗОПАСНОСТИ НЕЭЛЕКТРОТЕХНИЧЕСКОМУ ПЕРСОНАЛУ",
		image: "base_cards/base_card3.svg",
	},
];

const services = [
	{
		title: "ОЦЕНКА ПРОФЕССИОНАЛЬНЫХ РИСКОВ",
		description:
			"Процедура по выявлению всех возможных опасностей на рабочем месте и оценке уровня их воздействия на работника во время трудовой деятельности",
		price: "от 400 ₽/рм",
	},
	{
		title: "СПЕЦИАЛЬНАЯ ОЦЕНКА УСЛОВИЙ ТРУДА",
		description:
			"Обязательная процедура по оценке воздействия вредных производственных факторов на работника, предусмотренная Трудовым кодексом РФ",
		price: "от 900 ₽/рм",
	},
	{
		title: "ПРОИЗВОДСТВЕННЫЙ КОНТРОЛЬ",
		description:
			"Лабораторные исследования производственной среды. Перечень контролируемых факторов значительно шире, чем при проведении СОУТ",
		price: "от 300 ₽/фактор",
	},
	{
		title: "АУТСОРСИНГ ОХРАНЫ ТРУДА",
		description:
			"Передача функций специалиста по охране труда сторонней аккредитованной организации. Эффективное решение для малого и среднего бизнеса",
		price: "от 7 000 ₽/мес",
	},
	{
		title: "АУДИТ ОХРАНЫ ТРУДА",
		description:
			"Детальный анализ документации по охране труда, направленный на выявление и устранение нарушений трудового законодательства",
		price: "от 10 000 ₽",
	},
	{
		title: "СОПРОВОЖДЕНИЕ ПРИ ПРОВЕРКАХ ГИТ",
		description:
			"Подготовка и разработка необходимого пакета документов в рамках проверки и снижение величины штрафа по итогам надзорного мероприятия",
		price: "от 25 000 ₽",
	},
	{
		title: "РАЗРАБОТКА ДОКУМЕНТОВ/ПОСТРОЕНИЕ СУОТ",
		description:
			"Комплексная разработка документации по охране труда. Такое решение позволяет сэкономить время организаций, которые приняли решение вести охрану труда самостоятельно",
		price: "от 10 000 ₽",
	},
	{
		title: "ОБУЧЕНИЕ ПО ОХРАНЕ ТРУДА",
		description:
			"Обучение по охране труда является обязательным мероприятием и позволяет работникам приобрести знания и навыки, необходимые для безопасного производства работ",
		price: "от 600 ₽",
	},
	{
		title: "РАССЛЕДОВАНИЕ НЕСЧАСТНЫХ СЛУЧАЕВ",
		description:
			"Привлечение экспертной организации позволяет в том числе значительно снизить риски и издержки, связанные с произошедшим несчастным случаем",
		price: "от 25 000 ₽",
	},
];

const autCards = [
	{
		title: "КАДРОВОЕ ДЕЛОПРОИЗВОДСТВО",
		services: [
			"Ведение трудовых отношений “под ключ”, от получения СНИЛС и ИНН до расчета отпускных",
			"Процедура воинского учета также реализуется нашими специалистами",
		],
	},
	{
		title: "МИГРАЦИОННЫЙ УЧЕТ",
		services: [
			"Отправка необходимых уведомлений и документов в органы УМВД и УФМС",
			"Контроль платежей по патентам",
			"Помощь в постановке иностранного гражданина на учет по месту пребывания",
		],
	},
	{
		title: "ОХРАНА ТРУДА",
		services: [
			"Ведение всех процедур охраны труда: от вводного инструктажа до оценки профессиональных рисков",
			"Расследование возможных несчастных случаев также осуществляется нашими силами",
		],
	},
	{
		title: "бухгалтерия",
		services: [
			"Расчет и выплата заработной платы и иных выплат, причитающихся работникам",
			"Расчет и перечисление в бюджет налогов и взносов с ФОТ",
		],
	},
	{
		title: "ЮРИДИЧЕСКОЕ СОПРОВОЖДЕНИЕ",
		services: [
			"Сопровождение получения Патентов для иностранных граждан",
			"Юридическое сопровождение иностранных граждан в случае задержания их органами по вопросам миграционного учета",
		],
	},
	{
		title: "ВЗАИМОДЕЙСТВИЕ С ОРГАНАМИ НАДЗОРА",
		services: [
			"Мы берем на себя все коммуникации с органами надзора по вопросам, связанными с переданным персоналом",
			"Мы гарантируем безукоризненное исполнение требований законодательства",
		],
	},
];

const preimCards = [
	{
		title: "Ваша Уверенность",
		description:
			"Наша главная цель - обеспечить уверенность клиентов в каждом шаге. Мы работаем открыто и в полном соответствии с трудовым и налоговым законодательством РФ",
		image: "benefits/support.svg",
	},
	{
		title: "Аккредитация",
		description:
			"Группа Компаний “Труд” - аккредитованное частное агентство занятости, оказывающее услуги по аутстаффингу не только в Санкт-Петербурге, но и в других регионах РФ",
		image: "benefits/confidence.svg",
	},
	{
		title: "Полное сопровождение",
		description:
			'Группа Компаний "Труд" предлагает своим партнерам не просто услугу аутстаффинга, а полноценное сопровождение на всех этапах. Мы непременно находим решение, которое позволит нашим Клиентам получить максимальную выгоду от сотрудничества с нами',
		image: "benefits/accreditation.svg",
	},
];

export default function Main({ MainInfoItem }) {
	const settings = {
		dots: true, // Показываем индикаторы
		infinite: true, // Бесконечный цикл
		speed: 500, // Скорость переключения
		slidesToShow: 3, // Показывать 3 изображения на экране
		slidesToScroll: 1, // Скроллить по одному слайду
		arrows: true, // Добавляем стрелки
		responsive: [
			{
				breakpoint: 1280, // Планшет
				settings: {
					slidesToShow: 2, // Показывать 2 изображения
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 768, // Телефон
				settings: {
					slidesToShow: 1, // Показывать 1 изображение
					slidesToScroll: 1,
				},
			},
		],
	};

	const [selectedImage, setSelectedImage] = useState(MainInfoItem.logo);
	const [animationComplete, setAnimationComplete] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		if (animationComplete) {
			console.log("Переход на новую страницу.");
			//navigate("/Med");
			setAnimationComplete(false); // Сброс после перехода
		}
	}, [animationComplete, navigate]);

	const handlePageChange = () => {
		console.log("Анимация завершена, устанавливаем флаг.");
		setAnimationComplete(true);
	};

	const handleImageChange = (newImage) => {
		console.log("Выбрано новое изображение:", newImage); // Проверяем, что изображение передается
		setSelectedImage(newImage);
		setAnimationComplete(false);
	};

	return (
		<main className="main">
			<MainInfo
				selectedImage={selectedImage}
				handleImageChange={handleImageChange}
				handlePageChange={handlePageChange}
				MainInfoItem={MainInfoItem}
			/>

			{screenWidth() <= 768 ? (
				<div className="main-cards">
					{MainInfoItem.cards.map((card) => (
						<MainInfoCard
							key={uniqueId()}
							className="main-cards__card"
							title={card.title}
							description={card.description}
							backgroundImage={card.backgroundImage}
						/>
					))}
				</div>
			) : screenWidth() <= 1280 ? (
				<Slider {...settings}>
					{MainInfoItem.cards.map((card) => (
						<MainInfoCard
							key={uniqueId()}
							className="main-cards__card"
							title={card.title}
							description={card.description}
							backgroundImage={card.backgroundImage}
						/>
					))}
				</Slider>
			) : (
				<div className="main-cards_desktop">
					{MainInfoItem.cards.map((card) => (
						<MainInfoCard
							key={uniqueId()}
							className="main-cards__card_desktop"
							title={card.title}
							description={card.description}
							backgroundImage={card.backgroundImage}
						/>
					))}
				</div>
			)}
		</main>
	);
}

export const MainInfoCard = ({
	title,
	description,
	backgroundImage,
	className,
}) => {
	return (
		<div className={className}>
			<img src={backgroundImage} alt="card_icon" />
			<p>{description}</p>
			<span>{title}</span>
		</div>
	);
};

const MainInfo = ({
	selectedImage,
	handleImageChange,
	handlePageChange,
	MainInfoItem,
}) => {
	return (
		<div className="main_plus_leftmenu">
			<LeftMenu setImage={handleImageChange} />
			<div className="main-big__card">
				<div className="main-big__logo">
					{selectedImage && (
						<motion.img
							src={selectedImage}
							initial={{ opacity: 0, x: -100 }}
							animate={{ opacity: 1, x: 0 }}
							transition={{ duration: 0.5 }}
							className="animated-img big__leftside"
							onAnimationComplete={handlePageChange}
						/>
					)}
				</div>
				<div className="big__rightside">
					<h1>{MainInfoItem.title}</h1>

					<p dangerouslySetInnerHTML={{ __html: MainInfoItem.description }} />

					<div className="big__btns">
						<a href="#scrollServices">
							<button>Услуги и цены</button>
						</a>
						<button style={{ backgroundColor: MainInfoItem.buttonColor }}>
							Оставить заявку
						</button>
					</div>
				</div>

				<LeftMenu setImage={handleImageChange} isTablet={true} />
			</div>
		</div>
	);
};

const ServiceBlock = ({ title, description, price }) => {
	return (
		<div className="service-block">
			<div className="service-top">
				<h3>{title}</h3>
				<p>{description}</p>
			</div>

			<div className="service-price">
				<span>{price}</span>
				<a href="#" className="more-info">
					Подробнее
				</a>
			</div>

			<i></i>
		</div>
	);
};

export const Uslugi = () => {
	const settings = {
		dots: true, // Показываем индикаторы
		infinite: true, // Бесконечный цикл
		speed: 500, // Скорость переключения
		slidesToShow: 1, // Показываем 1 изображение на ПК
		slidesToScroll: 1, // Скроллить по одному слайду
		arrows: true, // Добавляем стрелки
		responsive: [
			{
				breakpoint: 1280, // Планшет
				settings: {
					slidesToShow: 2, // Показываем 4 блока, как 2 колонки
					slidesToScroll: 2, // Скроллим по одной колонке
					rows: 1, // Показываем 2 ряда в каждом слайде
				},
			},
			{
				breakpoint: 425, // Телефон
				settings: {
					slidesToShow: 1, // Показываем 1 блок на экране
					slidesToScroll: 1, // Скроллим по одному
				},
			},
		],
	};

	return (
		<main className="usl" id="scrollServices">
			<h2 style={{ color: "#fff" }}>Услуги нашей компании</h2>

			<div className="services-container">
				{services.map((service, index) => (
					<ServiceBlock
						key={index}
						title={service.title}
						description={service.description}
						price={service.price}
					/>
				))}
			</div>

			<Slider {...settings}>
				{services.map(
					(service, index) =>
						// Проверяем, чтобы индекс был чётным
						index % 2 === 0 && (
							<div className="slide-container" key={index}>
								{" "}
								{/* Первая карточка в паре */}
								<ServiceBlock
									title={service.title}
									description={service.description}
									price={service.price}
								/>{" "}
								{/* Вторая карточка, проверяем, существует ли она */}
								{services[index + 1] && (
									<ServiceBlock
										title={services[index + 1].title}
										description={services[index + 1].description}
										price={services[index + 1].price}
									/>
								)}
							</div>
						)
				)}
			</Slider>

			<span>
				Цены, представленные на сайте, носят исключительно ознакомительный
				характер и не являются публичной офертой.
			</span>
		</main>
	);
};

const BaseCard = ({ card }) => {
	return (
		<div className="sprav__card">
			<img src={card.image} alt="base_card_image" />
			<div>
				<h5>{card.title}</h5>
			</div>
		</div>
	);
};

export const Spravoc = () => {
	const settings = {
		dots: true, // Показываем индикаторы
		infinite: true, // Бесконечный цикл
		speed: 500, // Скорость переключения
		slidesToShow: 2, // Показывать 3 изображения на экране
		slidesToScroll: 1, // Скроллить по одному слайду
		arrows: true, // Добавляем стрелки
		responsive: [
			{
				breakpoint: 1280, // Планшет
				settings: {
					slidesToShow: 2, // Показывать 2 изображения
					slidesToScroll: 2,
				},
			},
			{
				breakpoint: 425, // Телефон
				settings: {
					slidesToShow: 2, // Показывать 1 изображение
					slidesToScroll: 1,
				},
			},
		],
	};

	return (
		<main className="spravoc">
			<h2>справочник специалиста по охране труда</h2>

			{screenWidth() <= 425 || screenWidth() > 1280 ? (
				<div className="sprav__cards">
					{baseCards.map((card) => (
						<BaseCard card={card} />
					))}
				</div>
			) : (
				screenWidth() <= 1280 && (
					<Slider {...settings}>
						{baseCards.map((card) => (
							<BaseCard card={card} />
						))}
					</Slider>
				)
			)}

			<a href="">
				Смотреть всё <p></p>
			</a>
		</main>
	);
};

export const BaseZ = () => {
	const settings = {
		dots: true, // Показываем индикаторы
		infinite: true, // Бесконечный цикл
		speed: 500, // Скорость переключения
		slidesToShow: 2, // Показывать 3 изображения на экране
		slidesToScroll: 1, // Скроллить по одному слайду
		arrows: true, // Добавляем стрелки
		responsive: [
			{
				breakpoint: 1280, // Планшет
				settings: {
					slidesToShow: 2, // Показывать 2 изображения
					slidesToScroll: 2,
				},
			},
			{
				breakpoint: 425, // Телефон
				settings: {
					slidesToShow: 2, // Показывать 1 изображение
					slidesToScroll: 1,
				},
			},
		],
	};
	return (
		<main className="baseZ">
			<h2>база знаний</h2>

			{screenWidth() <= 425 || screenWidth() > 1280 ? (
				<div className="baza__cards">
					<div className="baza__card">
						<i></i>
						<h4>Образцы документов по охране труда</h4>
					</div>
					<div className="baza__card">
						<i></i>
						<h4>Нормативные правовые документы</h4>
					</div>
					<div className="baza__card">
						<i></i>
						<h4>Термины и определения по охране труда</h4>
					</div>
				</div>
			) : (
				screenWidth() <= 1280 && (
					<Slider {...settings}>
						<div className="baza__card">
							<i></i>
							<h4>Образцы документов по охране труда</h4>
						</div>
						<div className="baza__card">
							<i></i>
							<h4>Нормативные правовые документы</h4>
						</div>
						<div className="baza__card">
							<i></i>
							<h4>Термины и определения по охране труда</h4>
						</div>
					</Slider>
				)
			)}

			<a href="">
				Смотреть всё <p></p>
			</a>
		</main>
	);
};

export const LeftMenu = ({ setImage, isTablet }) => {
	const handleClick = (imageSrc) => {
		setImage(imageSrc); // Устанавливаем выбранное изображение
	};

	return (
		<nav className={`${isTablet ? "leftmenu-tabl" : "leftmenu"}`}>
			<div className="leftmenu__item">
				<Link to="/">
					<img
						src="leftm/cot.svg"
						onClick={() => handleClick("logos/cot_logo.svg")}
						alt="Page 1"
					/>
				</Link>
			</div>
			<div className="leftmenu__item">
				<Link to="/Med">
					<img
						src="leftm/med.svg"
						onClick={() => handleClick("logos/med_logo.svg")}
						alt="Page 2"
					/>
				</Link>
			</div>
			<div className="leftmenu__item">
				<Link to="/Ucheb">
					<img
						src="leftm/trud.svg"
						onClick={() => handleClick("logos/trud_logo.svg")}
						alt="Page 3"
					/>
				</Link>
			</div>
			<div className="leftmenu__item">
				<Link to="/Center">
					<img
						src="leftm/center.svg"
						onClick={() => handleClick("logos/center_logo.svg")}
						alt="Page 4"
					/>
				</Link>
			</div>
			<div className="leftmenu__item">
				<Link to="/Aut">
					<img
						src="leftm/staff.svg"
						onClick={() => handleClick("logos/staff_logo.svg")}
						alt="Page 5"
					/>
				</Link>
			</div>
			<div className="leftmenu__item">
				<Link to="/Mas">
					<img
						src="leftm/massive.svg"
						onClick={() => handleClick("logos/massive_logo.svg")}
						alt="Page 6"
					/>
				</Link>
			</div>
		</nav>
	);
};

export const ObjSecurityContainer = ({ title, description }) => {
	return (
		<div>
			<div className="objectSec__container">
				<h5>{title}</h5>
				<span>{description}</span>
			</div>
		</div>
	);
};

export const ObjSecurity = () => {
	const settings = {
		dots: true, // Показываем индикаторы
		infinite: true, // Бесконечный цикл
		speed: 500, // Скорость переключения
		slidesToShow: 3, // Показываем 1 изображение на ПК
		slidesToScroll: 2, // Скроллить по одному слайду
		arrows: true, // Добавляем стрелки
		responsive: [
			{
				breakpoint: 1280, // Планшет
				settings: {
					slidesToShow: 2, // Показываем 4 блока, как 2 колонки
					slidesToScroll: 1, // Скроллим по одной колонке
				},
			},
			{
				breakpoint: 425, // Телефон
				settings: {
					slidesToShow: 1, // Показываем 1 блок на экране
					slidesToScroll: 1, // Скроллим по одному
				},
			},
		],
	};

	return (
		<div className="objectSec">
			<h2>ОБЪЕКТАМИ ОХРАНЫ ЯВЛЯЮТСЯ</h2>

			{screenWidth() <= 1280 ? (
				<Slider {...settings}>
					{objSecurityContent.map(
						(ObjSecurity, index) =>
							index % 2 === 0 && (
								<div className="slide-container" key={index}>
									{" "}
									<ObjSecurityContainer
										title={ObjSecurity.title}
										description={ObjSecurity.description}
									/>{" "}
									{objSecurityContent[index + 1] && (
										<ObjSecurityContainer
											title={objSecurityContent[index + 1].title}
											description={objSecurityContent[index + 1].description}
										/>
									)}
									{objSecurityContent[index + 2] && (
										<ObjSecurityContainer
											title={objSecurityContent[index + 2].title}
											description={objSecurityContent[index + 2].description}
										/>
									)}
								</div>
							)
					)}
				</Slider>
			) : (
				<Slider {...settings}>
					{objSecurityContent.map((ObjSecurity, index) => (
						<ObjSecurityContainer
							key={index}
							title={ObjSecurity.title}
							description={ObjSecurity.description}
						/>
					))}
				</Slider>
			)}
		</div>
	);
};

export const Shag = () => {
	const settings = {
		dots: true, // Показываем индикаторы
		infinite: true, // Бесконечный цикл
		speed: 500, // Скорость переключения
		slidesToShow: 2, // Показывать 3 изображения на экране
		slidesToScroll: 1, // Скроллить по одному слайду
		arrows: true, // Добавляем стрелки
		responsive: [
			{
				breakpoint: 1280, // Планшет
				settings: {
					slidesToShow: 2, // Показывать 2 изображения
					slidesToScroll: 2,
				},
			},
			{
				breakpoint: 768, // Телефон
				settings: {
					slidesToShow: 1, // Показывать 1 изображение
					slidesToScroll: 1,
				},
			},
		],
	};
	return (
		<main className="shag">
			<h2>вывод персонала за штат в четыре шага</h2>

			{screenWidth() <= 425 ? (
				<Slider {...settings}>
					<div>
						<div className="shag__card">
							<h5>Заключаем договор оказания услуг</h5>
							<i>1</i>
						</div>
					</div>
					<div>
						<div className="shag__card">
							<h5>Переводим персонал в штат нашей организации</h5>
							<i>2</i>
						</div>
					</div>
					<div>
						<div className="shag__card">
							<h5>Осуществляем мероприятия по допуску персонала к работе</h5>
							<i>3</i>
						</div>
					</div>
					<div>
						<div className="shag__card">
							<h5>Направляем переведенный персонал для производства работ</h5>
							<i>4</i>
						</div>
					</div>
				</Slider>
			) : (
				<div className="shag__cards">
					<div className="shag__card">
						<h5>Заключаем договор оказания услуг</h5>
						<i>1</i>
					</div>
					<div className="shag__card">
						<h5>Переводим персонал в штат нашей организации</h5>
						<i>2</i>
					</div>
					<div className="shag__card">
						<h5>Осуществляем мероприятия по допуску персонала к работе</h5>
						<i>3</i>
					</div>
					<div className="shag__card">
						<h5>Направляем переведенный персонал для производства работ</h5>
						<i>4</i>
					</div>
				</div>
			)}
		</main>
	);
};

export const Osnov = () => {
	return (
		<main className="osnov">
			<h2>Основные предпосылки для передачи персонала:</h2>
			<div className="osnov__container">
				<img className="osnov__img" src="chelibobicki.svg" alt="cheliki" />

				<ul className="osnov__ul">
					<li>
						Значительный рост штатной численности, который может повлечь переход
						на ОСНО
					</li>
					<li>Финансовая (налоговая) оптимизация для компаний на ОСНО</li>
					<li>
						Стремление к снижению административных рисков, связанных с
						управлением персоналом
					</li>
					<li>Сокращение нагрузки на административный персонал организации</li>
					<li>
						Отсутствие необходимых компетенций для ведения трудовых отношений с
						иностранными гражданами
					</li>
				</ul>
			</div>
		</main>
	);
};

const PreimCard = ({ title, description, image }) => {
	return (
		<div className="preim__card">
			<div className="preim-card__top">
				<img src={image} alt="benefit_icon" />
				<h4>{title}</h4>
			</div>
			<p>{description}</p>
		</div>
	);
};

export const Preim = () => {
	const settings = {
		dots: true, // Показываем индикаторы
		infinite: true, // Бесконечный цикл
		speed: 500, // Скорость переключения
		slidesToShow: 3, // Показывать 3 изображения на экране
		slidesToScroll: 1, // Скроллить по одному слайду
		arrows: true, // Добавляем стрелки
		responsive: [
			{
				breakpoint: 1280, // Планшет
				settings: {
					slidesToShow: 2, // Показывать 2 изображения
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 768, // Телефон
				settings: {
					slidesToShow: 1, // Показывать 1 изображение
					slidesToScroll: 1,
				},
			},
		],
	};

	return (
		<main className="preim">
			<h2>Преимущества обращения в нашу компанию</h2>

			<div className="preim__wrapper">
				<div className="preim__container">
					{screenWidth() <= 1280 ? (
						<Slider {...settings}>
							{preimCards.map((card) => (
								<PreimCard
									key={uniqueId()}
									title={card.title}
									description={card.description}
									image={card.image}
								/>
							))}
						</Slider>
					) : (
						<div className="preim__leftside">
							{preimCards.map((card) => (
								<PreimCard
									key={uniqueId()}
									title={card.title}
									description={card.description}
									image={card.image}
								/>
							))}
						</div>
					)}
				</div>

				<img className="scan" src="documents/scan.svg" alt="scan" />
			</div>
		</main>
	);
};

const CardAut = ({ title, services }) => {
	return (
		<div className="card-aut">
			<h4>{title}</h4>

			<ul>
				{services.map((service) => (
					<li key={uniqueId()}>{service}</li>
				))}
			</ul>
			<i></i>
		</div>
	);
};

export const AutB = () => {
	const settings = {
		dots: true, // Показываем индикаторы
		infinite: true, // Бесконечный цикл
		speed: 500, // Скорость переключения
		slidesToShow: 2, // Показывать 3 изображения на экране
		slidesToScroll: 1, // Скроллить по одному слайду
		arrows: true, // Добавляем стрелки
		responsive: [
			{
				breakpoint: 1280, // Планшет
				settings: {
					slidesToShow: 2, // Показывать 2 изображения
					slidesToScroll: 2,
				},
			},
			{
				breakpoint: 768, // Телефон
				settings: {
					slidesToShow: 1, // Показывать 1 изображение
					slidesToScroll: 1,
				},
			},
		],
	};

	return (
		<main className="AutB">
			<div className="AutB__info_container">
				<h3>КОМУ И КОГДА НЕОБХОДИМ АУТСТАФФИНГ ПЕРСОНАЛА</h3>
				<h6>
					Услуга отлично подходит для компаний, стремящихся не просто
					приспосабливаться к быстро меняющимся требованиям рынка, но и вести
					рынок вперёд. Аутстаффинг - это современный, нетривиальный и крайне
					эффективный способ оптимизации бизнес-процессов
				</h6>
			</div>
			<div className="AutB__info_container">
				<h3 id="scrollServices">СТОИМОСТЬ АУТСТАФФИНГА</h3>
				<h6>
					Цена на услуги аутстаффинга персонала - это гибкий и индивидуально
					рассчитываемый показатель, который зависит от множества факторов, в
					том числе от объема передаваемого нам персонала, гражданства
					работников, планируемой продолжительности сотрудничества, общей
					налогооблагаемой базы в рамках реализации проекта и прочих факторов
				</h6>
			</div>

			<div className="aut-btn">
				<h5>НАИМЕНОВАНИЕ УСЛУГИ</h5>
				<h5>Стоимость</h5>
			</div>

			<div className="grid-aut">
				<table>
					<tbody>
						<tr>
							<th scope="row">Аутстаффинг персонала (граждане РФ)</th>
							<td>от 3500</td>
						</tr>
						<tr>
							<th scope="row">Аутстаффинг персонала (иностранные граждане)</th>
							<td>от 4000</td>
						</tr>
						<tr>
							<th scope="row">Оформление патента “под ключ”</th>
							<td>от 29000</td>
						</tr>
					</tbody>
				</table>
			</div>

			<h6 className="aut_table_info">
				Наша задача - создание условий, при которых наши заказчики получат
				реально ощутимые положительные последствия от работы с нами, как
				материальные в виде экономии средств, так и нематериальные в виде
				снижения нагрузки на административный персонал <br />
				<span>
					Цены, представленные на сайте, носят исключительно ознакомительный
					характер и не являются публичной офертой.
				</span>
			</h6>

			<h3>ЧТО ВХОДИТ В УСЛУГИ АУТСТАФФИНГА?</h3>

			{screenWidth() <= 768 ? (
				<Slider {...settings}>
					{autCards.map(
						(card, index) =>
							index % 2 === 0 && (
								<div className="slide-container" key={uniqueId()}>
									<CardAut title={card.title} services={card.services} />
									{autCards[index + 1] && (
										<CardAut
											key={uniqueId()}
											title={autCards[index + 1].title}
											services={autCards[index + 1].services}
										/>
									)}
								</div>
							)
					)}
				</Slider>
			) : (
				<div className="cards-aut">
					{autCards.map((card) => (
						<CardAut
							key={uniqueId()}
							title={card.title}
							services={card.services}
						/>
					))}
				</div>
			)}

			<Form />

			<Osnov />
			<Preim />
		</main>
	);
};

export const ServiceCardsContainer = ({ cards, title }) => {
	const settings = {
		dots: true, // Показываем индикаторы
		infinite: true, // Бесконечный цикл
		speed: 500, // Скорость переключения
		slidesToShow: 3, // Показывать 3 изображения на экране
		slidesToScroll: 1, // Скроллить по одному слайду
		arrows: true, // Добавляем стрелки
		responsive: [
			{
				breakpoint: 1280, // Планшет
				settings: {
					slidesToShow: 2, // Показывать 2 изображения
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 425, // Телефон
				settings: {
					slidesToShow: 1, // Показывать 1 изображение
					slidesToScroll: 1,
				},
			},
		],
	};
	return (
		<div className="serviceCards_container">
			<h2>{title}</h2>
			{screenWidth() <= 1280 ? (
				<Slider {...settings}>
					{cards.map((card, index) => (
						<ServiceCard key={index} card={card} />
					))}
				</Slider>
			) : (
				<div className="main__cards">
					{cards.map((card, index) => (
						<ServiceCard key={index} card={card} />
					))}
				</div>
			)}
		</div>
	);
};

export const ServiceCard = ({ card }) => {
	return (
		<div className="main-cards__card-obraz">
			<h5>{card.title}</h5>

			<div className="card-ucheb-bottom">
				<div>
					{card.category ? (
						<span className="card-ucheb-info">{card.category} РАЗРЯД</span>
					) : (
						<span className="card-ucheb-info">
							<p></p> От {card.time} часов
						</span>
					)}

					{card.price ? (
						<span className="card-ucheb-price">
							<p></p> {card.price}₽
						</span>
					) : (
						<span className="card-ucheb-price">По запросу</span>
					)}
				</div>

				<a href="">Подробнее</a>
			</div>
			<img src={card.icon} alt="service_icon" />
		</div>
	);
};

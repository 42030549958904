import Header from "../header/header";
import Main, {AutB, BaseZ, Shag, Spravoc, Uslugi} from "../main/main";
import Form from "../form/form";
import Review from "../review/review";
import SliderComp from "../slider/slider";
import Footer from "../footer/footer";
import { mainInfoContent } from "../main/main";

function Aut() {
    return (
        <>
            <Header/>
            <Main MainInfoItem={mainInfoContent.aut}/>
            <Shag/>
            <AutB/>
            <Footer/>
        </>
    )
}

export default Aut;


import Header from "../header/header";
import Main, { ServiceCardsContainer } from "../main/main";
import Form from "../form/form";
import Review from "../review/review";
import SliderComp from "../slider/slider";
import Slider from "react-slick";
import Footer from "../footer/footer";
import { mainInfoContent } from "../main/main";
import { screenWidth } from "../main/main";

const securityEducation = [
	{
		title: "ОХРАННИК",
		category: "4",
		price: "5000",
		icon: "education_services/4.svg",
	},
	{
		title: "ОХРАННИК",
		category: "5",
		price: "6000",
		icon: "education_services/5.svg",
	},
	{
		title: "ОХРАННИК",
		category: "6",
		price: "6500",
		icon: "education_services/6.svg",
	},
];

const securityQualification = [
	{
		title: "ОХРАННИК",
		category: "4",
		price: "3000",
		icon: "education_services/4.svg",
	},
	{
		title: "ОХРАННИК",
		category: "5",
		price: "4000",
		icon: "education_services/5.svg",
	},
	{
		title: "ОХРАННИК",
		category: "6",
		price: "4500",
		icon: "education_services/6.svg",
	},
];

const securityCheckup = [
	{
		title: "ОХРАННИК",
		category: "4",
		price: "3600",
		icon: "education_services/4.svg",
	},
	{
		title: "ОХРАННИК",
		category: "5",
		price: "4000",
		icon: "education_services/5.svg",
	},
	{
		title: "ОХРАННИК",
		category: "6",
		price: "4800",
		icon: "education_services/6.svg",
	},
];

const managerEducation = [
	{
		title: "ОХРАННИК",
		category: "4",
		price: "12000",
		icon: "education_services/4.svg",
	},
	{
		title: "ОХРАННИК",
		category: "5",
		price: "6000",
		icon: "education_services/5.svg",
	},
	{
		title: "ОХРАННИК",
		category: "6",
		icon: "education_services/6.svg",
	},
];

const medCheckup = [
	{
		title: "ОХРАННИК",
		category: "4",
		price: "5000",
		icon: "education_services/4.svg",
	},
	{
		title: "ОХРАННИК",
		category: "5",
		price: "6000",
		icon: "education_services/5.svg",
	},
	{
		title: "ОХРАННИК",
		category: "6",
		price: "6500",
		icon: "education_services/6.svg",
	},
];

const medOther = [
	{
		title: "ОХРАННИК",
		category: "4",
		price: "3000",
		icon: "education_services/4.svg",
	},
	{
		title: "ОХРАННИК",
		category: "5",
		price: "4000",
		icon: "education_services/5.svg",
	},
	{
		title: "ОХРАННИК",
		category: "6",
		price: "4500",
		icon: "education_services/6.svg",
	},
];

const uniformCards = [
	{
		name: "Костюм «РИО»",
		equipment: "Куртка/Брюки",
		clothType: "Смесовая",
		color: "Черный",
		size: "44-56",
		price: "2200",
        image: "uniform/uniform_rio.svg"
	},
	{
		name: "Костюм «Нова»",
		equipment: "Куртка/Брюки",
		clothType: "РИП-СТОП",
		color: "Черный",
		size: "44-56",
		price: "3200",
        image: "uniform/uniform_nova.svg"
	},
];

const Services = () => {
	return (
		<main className="Obraz Center" id="scrollServices">
			<h3>УСЛУГИ НАШЕЙ КОМПАНИИ ДЛЯ ЧОП</h3>
			<ServiceCardsContainer
				cards={securityEducation}
				title={"Профессиональное обучение по профессии охранник"}
			/>
			<ServiceCardsContainer
				cards={securityQualification}
				title={"Повышение квалификации для сотрудников охраны"}
			/>
			<ServiceCardsContainer
				cards={securityCheckup}
				title={"Ежегодная периодическая проверка охранников"}
			/>
			<ServiceCardsContainer
				cards={managerEducation}
				title={"Обучение руководителей частных охранных организаций"}
			/>

			<h3>Медицинские услуги для ЧОП</h3>
			<ServiceCardsContainer
				cards={medCheckup}
				title={"Медицинское освидетельствование для частных охранников"}
			/>
			<ServiceCardsContainer
				cards={medOther}
				title={"Прочие медицинские услуги"}
			/>

			<p>
				Центр Содействия осуществляет комплекс услуг по медицинскому обеспечению
				охранной деятельности: от медицинского освидетельствования в
				соответствии с Приказом Министерства здравоохранения РФ от 26 ноября
				2020 года №1252н  до организации проведения предрейсовых медицинских
				осмотров для охранников-водителей.
			</p>

			<p>
				Цены, представленные на сайте, носят исключительно ознакомительный
				характер и не являются публичной офертой.
			</p>
		</main>
	);
};

const UniformCard = ({ card }) => {
	return (
		<div className="uniform_card">
			<div className="uniform_specs">
				<span>{card.name}</span>
				<span>Комплект: {card.equipment}</span>
				<span>Ткань: {card.clothType}</span>
				<span>Цвет: {card.color}</span>
				<span>Размеры: {card.size}</span>
				<span>Стоимость: {card.price}</span>
			</div>
            <button>Оставить заявку</button>
            <img src={card.image} alt="uniform_image" />
		</div>
	);
};

const Uniform = () => {
    const settings = {
        dots: true,  // Показываем индикаторы
        infinite: true,  // Бесконечный цикл
        speed: 500,  // Скорость переключения
        slidesToShow: 3,  // Показывать 3 изображения на экране
        slidesToScroll: 1,  // Скроллить по одному слайду
        arrows: true,  // Добавляем стрелки
        responsive: [
            {
                breakpoint: 1024,  // Планшет
                settings: {
                    slidesToShow: 2,  // Показывать 2 изображения
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 425,  // Телефон
                settings: {
                    slidesToShow: 1,  // Показывать 1 изображение
                    slidesToScroll: 1,
                }
            }
        ]
    };

	return (
		<main className="Obraz Center uniform">
			<h3>Пошив форменной одежды для охранных предприятий</h3>
			<p>
				В рамках комплексного обслуживания частных охранных предприятий мы
				осуществляем поставки качественной и удобной форменной одежды для
				охранников.
			</p>

            {screenWidth() <= 425 ? (
                <Slider {...settings}>
                    {uniformCards.map((card) => (
                        <UniformCard card={card} />
                    ))}
                </Slider>
            ) : (
                <div className="uniform_cards_container">
                    {uniformCards.map((card) => (
                        <UniformCard card={card} />
                    ))}
                </div>
            )}
		</main>
	);
};

function Center() {
	return (
		<>
			<Header />
			<Main MainInfoItem={mainInfoContent.center} />
			<Services />
			<Uniform />
			<Form />
			<SliderComp />
			<Footer />
		</>
	);
}

export default Center;

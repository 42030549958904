import Header from "../header/header";
import Main, {BaseZ, ObjSecurity, Spravoc, Uslugi} from "../main/main";
import Form from "../form/form";
import Review from "../review/review";
import SliderComp from "../slider/slider";
import Footer from "../footer/footer";
import Slider from "react-slick";
import React, {useState} from "react";
import "./../css/Obraz.css";
import { mainInfoContent } from "../main/main";

export default function Med() {
    const settings = {
        dots: true,  // Показываем индикаторы
        infinite: true,  // Бесконечный цикл
        speed: 500,  // Скорость переключения
        slidesToShow: 3,  // Показывать 3 изображения на экране
        slidesToScroll: 1,  // Скроллить по одному слайду
        arrows: true,  // Добавляем стрелки
        responsive: [
            {
                breakpoint: 1280,  // Планшет
                settings: {
                    slidesToShow: 2,  // Показывать 2 изображения
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 425,  // Телефон
                settings: {
                    slidesToShow: 1,  // Показывать 1 изображение
                    slidesToScroll: 1,
                }
            }
        ]
    };
    return (
        <>
            <Header/>
            <Main MainInfoItem={mainInfoContent.med}/>
            <Uslugi/>
            <main className="rasres">
                <h2>Разрешительная документация</h2>

                <Slider {...settings}>
                    <img className="med_document" src="documents/uniomed_doc1.svg" alt="med_document"/>
                    <img className="med_document" src="documents/uniomed_doc2.svg" alt="med_document"/>
                    <img className="med_document" src="documents/uniomed_doc3.svg" alt="med_document"/>
                </Slider>
            </main>
            <Form/>
            <SliderComp/>
            <Footer/>
        </>
    )
}



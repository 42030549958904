import React from "react";
import Slider from "react-slick";
import './../css/review.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Пути к изображениям отзывов
const SliderImg = [
    { id: 1, image: './Group 279.svg'},
    { id: 2, image: './Group 280.svg' },
    { id: 3, image: './Group 281.svg' },
    { id: 4, image: './Group 282.svg' },
    { id: 5, image: './Group 290.svg' },
    { id: 6, image: './Group 281.svg' },
    { id: 7, image: './Group 282.svg' },
    { id: 8, image: './Group 290.svg' },
];

export const SliderComp = () => {
    const settings = {
        dots: true,  // Показываем индикаторы
        infinite: true,  // Бесконечный цикл
        speed: 500,  // Скорость переключения
        slidesToShow: 5,  // На ПК показывать 5 изображений
        slidesToScroll: 1,  // Скролл по 1 слайду
        arrows: true,  // Стрелки
        responsive: [
            {
                breakpoint: 1400,  // Планшет
                settings: {
                    slidesToShow: 1,  // Показывать 3 изображения горизонтально
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 768,  // Телефон
                settings: {
                    slidesToShow: 1,  // Показывать 1 слайд с 3 карточками внутри
                    slidesToScroll: 1,
                }
            }
        ]
    };

    const groupedImages = [];
    for (let i = 0; i < SliderImg.length; i += 3) {
        groupedImages.push(SliderImg.slice(i, i + 3));
    }

    return (
        <div className="reviews-carousel adpt">
            <h2 style={{color: "#fff", marginTop: 145}}>Нам доверяют</h2>
            <Slider {...settings}>
                {groupedImages.map((group, index) => (
                    <div key={index} className="review-image-group adap">
                        {group.map((slider) => (
                            <div key={slider.id} className="review-image-container">
                                <img src={slider.image} alt={`Партнеры ${slider.id}`} className="review-image"/>
                            </div>
                        ))}
                    </div>
                ))}
            </Slider>
            <Slider {...settings}>
                {SliderImg.map((slider) => (
                    <div key={slider.id} className="review-image-container pc">
                        <img src={slider.image} alt={`Отзыв ${slider.id}`} className="review-image"/>
                    </div>
                ))}
            </Slider>
        </div>
    );
};


export default SliderComp;

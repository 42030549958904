import React from "react";
import Slider from "react-slick";
import './../css/review.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Пути к изображениям отзывов
const reviewsImages = [
    { id: 1, image: './sssadwad.svg' },
    { id: 2, image: '/sssadww.svg' },
    { id: 3, image: '/sssadww.svg' },
];

export const Review = () => {
    const settings = {
        dots: true,  // Показываем индикаторы
        infinite: true,  // Бесконечный цикл
        speed: 500,  // Скорость переключения
        slidesToShow: 3,  // Показывать 3 изображения на экране
        slidesToScroll: 1,  // Скроллить по одному слайду
        arrows: true,  // Добавляем стрелки
        responsive: [
            {
                breakpoint: 1400,  // Планшет
                settings: {
                    slidesToShow: 2,  // Показывать 2 изображения
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 768,  // Телефон
                settings: {
                    slidesToShow: 1,  // Показывать 1 изображение
                    slidesToScroll: 1,
                }
            }
        ]
    };

    return (
        <div className="reviews-carousel">
            <h2>Отзывы</h2>
            <Slider {...settings}>
                {reviewsImages.map((review) => (

                        <img  key={review.id} src={review.image} alt={`Отзыв ${review.id}`} className="review-image"/>

                ))}
            </Slider>
        </div>
    );
};

export default Review;

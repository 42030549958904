import Header from "../header/header";
import Main, { ServiceCardsContainer } from "../main/main";
import Form from "../form/form";
import SliderComp from "../slider/slider";
import Footer from "../footer/footer";
import React, { useState } from "react";
import "./../css/Obraz.css";
import { mainInfoContent } from "../main/main";

const educationCards = [
	{
		title: "ОХРАНА ТРУДА",
		time: "8",
		price: "От 600",
		icon: "education_services/edu_serv_icon1.svg",
	},
	{
		title: "Работы на высоте",
		time: "12",
		price: "От 3000",
		icon: "education_services/edu_serv_icon2.svg",
	},
	{
		title: "ПЕРВАЯ ПОМОЩЬ",
		time: "8",
		price: "От 800",
		icon: "education_services/edu_serv_icon3.svg",
	},
];

const qualificationCards = [
	{
		title: "ПОЖАРНАЯ БЕЗОПАСНОСТЬ",
		time: "36",
		price: "От 3000",
		icon: "education_services/edu_serv_icon4.svg",
	},
	{
		title: "ГРАЖДАНСКАЯ ОБОРОНА И ЧС",
		time: "72",
		price: "От 3000",
		icon: "education_services/edu_serv_icon5.svg",
	},
	{
		title: "ЭКОЛОГИЧЕСКАЯ БЕЗОПАСНОСТЬ",
		time: "38",
		price: "От 3000",
		icon: "education_services/edu_serv_icon6.svg",
	},
];

const programsCards = [
	{
		title: "ПРОФЕССИОНАЛЬНАЯ ПЕРЕПОДГОТОВКА",
		time: "250",
		price: "От 9000",
		icon: "education_services/edu_serv_icon7.svg",
	},
	{
		title: "РАБОЧИЕ СПЕЦИАЛЬНОСТИ",
		time: "24",
		price: "От 4000",
		icon: "education_services/edu_serv_icon8.svg",
	},
	{
		title: "ПОВЫШЕНИЕ КВАЛИФИКАЦИИ",
		time: "16",
		price: "От 1000",
		icon: "education_services/edu_serv_icon9.svg",
	},
];

const supervisionCards = [
	{
		title: "электробезопасность",
		time: "38",
		price: "От 3000",
		icon: "education_services/edu_serv_icon10.svg",
	},
	{
		title: "тепловые установки",
		time: "72",
		price: "От 3000",
		icon: "education_services/edu_serv_icon11.svg",
	},
	{
		title: "промышленная безопасность",
		time: "16",
		price: "От 3000",
		icon: "education_services/edu_serv_icon12.svg",
	},
];

export function UchebC() {
	return (
		<main className="Obraz" id="scrollServices">
			<ServiceCardsContainer
				cards={educationCards}
				title={"ОБРАЗОВАТЕЛЬНЫЕ УСЛУГИ"}
			/>
			<ServiceCardsContainer
				cards={qualificationCards}
				title={"ОБЯЗАТЕЛЬНОЕ ПОВЫШЕНИЕ КВАЛИФИКАЦИИ ДЛЯ РУКОВОДИТЕЛЕЙ"}
			/>
			<ServiceCardsContainer
				cards={programsCards}
				title={"ОБЩЕОБРАЗОВАТЕЛЬНЫЕ ПРОГРАММЫ"}
			/>
			<ServiceCardsContainer cards={supervisionCards} title={"РОСТЕХНАДЗОР"} />
		</main>
	);
}

export default function Ucheb() {
	return (
		<>
			<Header />
			<Main MainInfoItem={mainInfoContent.ucheb} />
			<UchebC />
			<Form />
			<SliderComp />
			<Footer />
		</>
	);
}

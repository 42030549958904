import Header from "../header/header";
import Main, {BaseZ, ObjSecurity, Spravoc, Uslugi} from "../main/main";
import Form from "../form/form";
import Review from "../review/review";
import SliderComp from "../slider/slider";
import Footer from "../footer/footer";
import { mainInfoContent } from "../main/main";

function Massive() {
    return (
        <>
            <Header/>
            <Main MainInfoItem={mainInfoContent.massive}/>
            <Uslugi/>
            <ObjSecurity/>
            <Form/>
            <SliderComp/>
            <Footer/>
        </>
    )
}

export default Massive;


import Header from "../header/header";
import Main, {BaseZ, Spravoc, Uslugi} from "../main/main";
import Form from "../form/form";
import Review from "../review/review";
import SliderComp from "../slider/slider";
import Footer from "../footer/footer";
import { mainInfoContent } from "../main/main";

function Cot() {
    return (
            <>
                <Header/>
                <Main MainInfoItem={mainInfoContent.cot}/>
                <Uslugi/>
                <Spravoc/>
                <BaseZ/>
                <Form/>
                <Review/>
                <SliderComp/>
                <Footer/>
            </>
        )
}

export default Cot;


import './../css/header.css';

function Header() {
    return (
        <header  className="header__main">
            <div className="header__leftside">
                <div className="header-leftside__burger"></div>
                <div className="header-leftside__logo"></div>
            </div>

            <div className="header__rightside">
                <div className="header-rightside__top">

                    <a href="" className="header-top__adress"><span className="adress__icon"></span> info@gk-trud.ru</a>
                    <a href="" className="header-top__adress"><span className="adress__icon"></span> c 09:00 до 18:00 пн-пт</a>
                    <a href="" className="header-top__adress"><span className="adress__icon"></span> г. Санкт-Петербург, ул. Курская, д 19</a>
                    <a href="" className="header-top__adress"><span className="adress__icon"></span> 8 800 101 33 12</a>
                    <a href="" className="header-top__adress">8 812 640 78 22</a>
                    <span className="wts "></span>
                    <span className="tg "></span>
                    <span className="mail "></span>
                    <span className="tel"></span>
                    <span className="src "></span>
                </div>
                <nav className="header-rightside__bottom">
                    <a href="" className="header-bottom__adress"> Главная</a>
                    <a href="" className="header-bottom__adress"> О компании</a>
                    <a href="" className="header-bottom__adress"> Услуги</a>
                    <a href="" className="header-bottom__adress"> Контакты</a>
                </nav>
            </div>
        </header>
    );
}

export default Header;


import './App.css';
import './components/css/tablet.css';
import './components/css/mobile.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import {useState} from "react";
import Cot from "./components/pages/cot";
import Aut from "./components/pages/aut";
import Med from "./components/pages/med";
import Center from "./components/pages/center";
import Massive from "./components/pages/massive";
import Ucheb from "./components/pages/ucheb";



function App() {
    const [titleCard, setTitleCard] = useState("Sss");
  return (
      <div className="App">
          <Routes>
              <Route path="/" element={<Cot />} />
              <Route path="/Aut" element={<Aut />} />
              <Route path="/Mas" element={<Massive />} />
              <Route path="/Med" element={<Med />} />
              <Route path="/Center" element={<Center />} />
              <Route path="/Ucheb" titleCard={titleCard} element={<Ucheb />} />
          </Routes>
      </div>
  );
}

export default App;

import './../css/footer.css';

function Footer() {
    return (
        <footer>
            <div className="footer__top">
                <div className="footer__column">
                    <div className="logo__fot logo"></div>

                    <p>
                        ООО “Группа компаний “Труд”
                        ОГРН 1177847309969
                        ИНН 7810707150
                        КПП 781601001
                        Р/С 40702810432070011053
                        ФИЛИАЛ “САНКТ-ПЕТЕРБУРГСКИЙ”
                        АО “АЛЬФА-БАНК” г. САНКТ-ПЕТЕРБУРГ
                    </p>

                    <div className="aaasss">
                        <p>ООО “Группа компаний “Труд”</p>

                        <p>
                            <p>ОГРН  <p>1177847309969</p></p>
                            <p>ИНН  <p>7810707150</p></p>
                            <p>КПП 781601001</p>
                            <p>БИК <p>044030786</p></p>
                            <p>Р/С 40702810432070011053</p>
                        </p>

                        <p>
                            ФИЛИАЛ “САНКТ-ПЕТЕРБУРГСКИЙ”
                            АО “АЛЬФА-БАНК” г. САНКТ-ПЕТЕРБУРГ
                        </p>
                    </div>
                </div>
                <div className="footer__column">
                    <a href="">О НАС <p></p></a>
                    <a href="">КОНТАКТЫ <p></p></a>
                    <a href="">ЦЕНТР ОХРАНЫ ТРУДА <p></p></a>
                    <a href="">УЧЕБНЫЙ ЦЕНТР <p></p></a>
                    <a href="">МЕДИЦИНСКИЙ ЦЕНТР <p></p></a>
                </div>
                <div className="footer__column">
                    <a href="">АУТСТАФФИНГ ПЕРСОНАЛА <p></p></a>
                    <a href="" style={{alignItems: "10px"}}>СПРАВОЧНИК СПЕЦИАЛИСТА ПО ОТ <p style={{marginTop:"6px"}}></p></a>
                    <a href="">ЭКОЛОГИЧЕСКИЕ УСЛУГИ <p></p></a>
                    <a href="">ШКОЛА ОХРАННИКОВ <p></p></a>
                </div>
                <div className="footer__column two">
                    <div className="footer__min-col">
                        <span>
                            г. Санкт-Петербург, ул. Курская, 19<br/>
                            8 812 640 78 22<br/>
                            8 800 101 33 12<br/>
                            Звонок по России БЕСПЛАТНЫЙ<br/>
                        </span>
                    </div>

                    <div className="footer__min-col">
                        <span>
                            info@gk-trud.ru <br/>
                            Режим работы:<br/>
                            Пн-Пт 9:00-18:00<br/>
                            Сб-Вс - выходные дни<br/>
                        </span>
                    </div>
                </div>
                <div className="footer__column">
                    <div className="hide">
                        <span>Контакты</span>
                        <span >г. Санкт-Петербург, ул. Курская, 19</span>
                        <div className="footer__social">
                            <a href=""></a>
                            <a href=""></a>
                            <a href=""></a>
                            <a href=""></a>
                            <a href=""></a>
                        </div>
                    </div>
                    <div className="footer__social hide-ta">
                        <a href=""></a>
                        <a href=""></a>
                        <a href=""></a>
                        <a href=""></a>
                        <a href=""></a>
                    </div>

                    <div className="hide">
                        <a style={{color: "#01A7FF"}}  href=""><p></p>8 812 640 78 22</a>
                        <a style={{color: "#01A7FF"}}  href=""><p></p>8 800 101 33 12</a>
                        <p >Звонок по России БЕСПЛАТНЫЙ</p>
                    </div>

                    <div className="hide tata">
                        <a style={{color: "#01A7FF"}}  href=""><p></p>info@gk-trud.ru</a>
                        <p><p></p>РЕЖИМ РАБОТЫ: Пн-Пт 09:00-18:00</p>
                    </div>

                    <div className="qr">
                        <span>Присоединяйтесь в наш телеграм-канал!</span>
                        <div className="qr-code"></div>
                    </div>
                </div>
            </div>

            <hr/>

            <div className="footer__under">
                <span>2024 ГК ТРУД Общество с ограниченной ответственностью “Группа компаний “Труд”</span>

                <span>Настоящий сайт является объектом авторского права «Группы компаний «Труд»». Копирование, размножение, или иное использование материала без письменного разрешения автора не допускается</span>
            </div>
        </footer>
    );
}

export default Footer;

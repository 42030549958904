import './../css/form.css';

function Form() {
    return (
        <div className="form__container">
            <i></i>
            <i></i>
            <div className="form__leftside">
                <h2 style={{marginBottom: 20}}>вы все еще думаете?</h2>

                <h6 >Оставьте заявку, и наш менеджер свяжется с Вами, окажет необходимые консультации и ответит на все интересующие вопросы</h6>
            </div>

            {/*style={{opacity: "50%"}}*/}

            <form action="">
                <p>Имя</p>
                <input type="text" placeholder="Александр"/>
                <p>Номер телефона</p>
                <input type="tel" placeholder="+7 ()" style={{marginTop:19}}/>
                <div className="check-in"><input type="checkbox"/> <span style={{fontSize: 16}}>Я согласен на обработку персональных данных</span></div>
                <button>Отправить</button>
            </form>


        </div>
    );
}

export default Form;

